<template>
  <v-form ref="labForm">
    <v-container
      class="fill-height"
      fluid
    >
      <v-row :style="{'padding-bottom': matrix_height + 'px'}">
        <v-col
          xmd="6"
          xlg="8"
        >
          <v-card :loading="loading.lab_result">
            <v-card-title class="d-flex justify-space-between">
              <v-row>
                <v-col>
                  Lab Result <span v-if="lab_result.external_id">{{ lab_result.external_id }}</span>:
                  {{ $t('translate to', language, { language: $languages.find(x => x.value == language).text}) }}
                </v-col>
                <!-- <div>
                <client-icon
                  v-if="lab_result.client && $auth.check({ clients: ['*', 'view','edit'] })"
                  :key="lab_result.client.uuid"
                  :icon="lab_result.client.icon"
                  :name="lab_result.client.name"
                />
              </div> -->
                <v-spacer />
                <v-col cols="auto">
                  <v-select
                    v-model="language"
                    :items="$languages"
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-title>
            <v-divider />
            <v-card-text>
              <v-row
                v-for="item in indications"
                :key="item.id"
              >
                <v-col>
                  <v-text-field
                    v-model="item.indication.en"
                    label="Indication"
                    readonly
                    xfilled
                    color="primary"
                    outlined
                    counter
                    dense
                  />
                </v-col>
                <v-col
                  cols="auto"
                  class="px-0"
                >
                  <v-icon
                    small
                    class="mt-3"
                  >
                    fal fa-arrow-right
                  </v-icon>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.indication[language]"
                    :label="$tc('indication', 1, language) | capitalize"
                    :lang="language"
                    outlined
                    counter
                    dense
                  >
                    <template #append>
                      <v-btn
                        icon
                        @click="translateText(item.indication.en, language, v => $set(item.indication, language, v))"
                      >
                        <v-icon small>
                          fa fa-language
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-for="item in instructions.filter(x => questions.includes(x.key)).sort((a, b) => questions.indexOf(a.key) - questions.indexOf(b.key))"
                :key="item.key"
              >
                <v-col>
                  <v-textarea
                    v-model="item.instruction.en"
                    :label="$t(`questions.${item.key}`)"
                    dense
                    readonly
                    xfilled
                    outlined
                    auto-grow
                    counter
                    rows="4"
                  />
                </v-col>
                <v-col
                  cols="auto"
                  class="px-0"
                >
                  <v-icon
                    small
                    class="mt-4"
                  >
                    fal fa-arrow-right
                  </v-icon>
                </v-col>
                <v-col>
                  <v-textarea
                    v-model="item.instruction[language]"
                    :label="$t(`questions.${item.key}`, language)"
                    :lang="language"
                    dense
                    outlined
                    auto-grow
                    counter
                    rows="4"
                  >
                    <template #append>
                      <v-btn
                        icon
                        @click="translateText(item.instruction.en, language, v => $set(item.instruction, language, v))"
                      >
                        <v-icon small>
                          fa fa-language
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="success"
                @click="saveTranslations"
              >
                {{ $t('Save') }} Translations
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>

  export default {
    metaInfo () {
      return {
        title: 'Lab Result ' + this.lab_result.external_id + ' | Translate Interpretation',
      }
    },
    components: {
      // ClientIcon: () => import('@/components/ClientIcon.vue'),
      // FlagForReview: () => import('@/components/lab_result/FlagForReview.vue'),
      // MethodChip: () => import('@/components/MethodChip.vue'),
      // StatusNotes: () => import('@/components/lab_result/StatusNotes.vue'),
    },
    data: () => ({
      loading: {
        lab_result: false,
        button: false,
      },
      valid: true,
      language: 'en',
      lab_result: {},
      instructions: [],
      indications: [],
      drugs: [],
      matrix_height: 25,
      questions: [
        'alternatives',
        'one_choice_explained',
        'treated_when',
        'considerations',
        'treated_length',
        'infection_control',
      ],
    }),
    created () {
      this.fetchData()
    },
    methods: {
      saveTranslations () {
        this.loading.button = true
        this.axios.post('lab_results/' + this.$route.params.uuid + '/interpretation/translations', {
          language: this.language,
          instructions: this.instructions,
          indications: this.indications,
          drugs: this.drugs,
        })
          .then(() => {
            this.$toast.success('Translations saved')
            this.fetchData()
          })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.button = false })
      },
      fetchData () {
        this.loading.lab_result = true
        const promise = this.axios.get(`lab_results/${this.$route.params.uuid}/interpretation/translations`)

        return promise.then((response) => {
          this.language = response.data.language
          this.lab_result = response.data.lab_result
          this.instructions = response.data.instructions
          this.indications = response.data.indications
          this.drugs = response.data.drugs
        })
          .catch(error => {
            this.$toast.error(error)
          })
          .finally(() => { this.loading.lab_result = false })
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 4.5em;
  padding-top: 0.9em;

  span {
    max-width: 100px;
  }
}
</style>
